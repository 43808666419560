import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import { get, flatten } from 'lodash-es';
import { graphql, useStaticQuery } from 'gatsby';

import OptionChain from './OptionChain';
import DateContext from '../../../context/DateContext';
import {
  findContractExpirationDate,
  findContractStartDate
} from '../../../utils/functions';
import dayjs from '../../../utils/dayjs';
import { FUTURES_MONTH_CODES } from '../../../utils/constants';
import BlockStyles from '../../BlockStyles';

const propTypes = {
  symbol: PropTypes.string.isRequired,
  primary: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  template: PropTypes.string
};

const defaultProps = {
  symbol: '',
  primary: {},
  fields: [],
  template: ''
};

const Container = styled.div`
  h2 {
    color: ${prop('theme.colors.redPrimary')};
  }

  .block,
  .block-styles {
    padding: 0;
  }
`;

function HistoricalOptionChain({ symbol, primary, fields, template }) {
  const { selectedTimeframe, selectedDate } = useContext(DateContext);
  const [optionsData, setOptionsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const queryResult = useStaticQuery(graphql`
    query EventQuery {
      allPrismicEvent {
        edges {
          node {
            ...EventsInfo
          }
        }
      }
      allPrismicEventYear {
        edges {
          node {
            data {
              body {
                ... on PrismicEventYearDataBodyEvent {
                  primary {
                    event_date
                    event_title {
                      richText
                      text
                    }
                    holiday
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async (fromDate, ipfFileDate, symbol) => {
      setLoading(true);
      const params = new URLSearchParams();
      params.append('date', ipfFileDate);

      const url = `${process.env.IPF_API_URL ||
        'https://edgmwolixi.execute-api.us-east-2.amazonaws.com/prod/products'}?${params.toString()}`;
      const ipfResponse = await fetch(url);
      const ipfJson = await ipfResponse.json();

      if (!ipfJson) {
        setLoading(false);
        return;
      }

      const productData = ipfJson.productData[symbol];
      const optionsMonth =
        productData.frontMonthExpirationMonth === selectedTimeframe.month.label
          ? 'frontMonth'
          : 'backMonth';

      const optionsSymbols = get(productData, `${optionsMonth}Options`, []).map(
        (symbol) => `${symbol}{=d}`
      );

      if (!optionsSymbols) {
        if (isMounted) {
          setLoading(false);
        }
        return;
      }

      const toDate = get(
        productData,
        `${optionsMonth}ExpirationDate`,
        ''
      ).replaceAll('-', '');
      const dxFeedParams = new URLSearchParams();
      dxFeedParams.append('symbols', optionsSymbols);
      dxFeedParams.append('fromDate', fromDate);
      dxFeedParams.append('toDate', toDate);

      const dxFeedResponse = await fetch(
        `${process.env.GATSBY_DX_FEED_PROXY_URL ||
          process.env
            .DX_FEED_PROXY_URL}/api/contract-data?${dxFeedParams.toString()}`
      );

      dxFeedResponse
        .json()
        .then((responseJson) => {
          // dxfeed returns an array of arrays instead of
          // something better like an object with the symbols as keys :(
          if (isMounted) {
            const flattenedResponse = flatten(responseJson);
            setOptionsData(flattenedResponse);
            setLoading(false);
          }
        })
        .catch((err) => {
          if (isMounted) {
            console.error('Error parsing dxfeed data:', err.message);
            setLoading(false);
          }
        });
    };

    const month = selectedTimeframe.month.value;
    const year = selectedTimeframe.year.value;

    const {
      events,
      events_2021
    } = queryResult.allPrismicEvent.edges[0].node.data;
    const eventsByYear = queryResult.allPrismicEventYear.edges
      .flatMap((edge) => get(edge, 'node.data.body', []))
      .map((bodySection) => bodySection.primary);
    const allEvents = [...events, ...events_2021, ...eventsByYear];
    const holidays = allEvents
      .filter((event) => event.holiday === 'Yes')
      .map((event) => event.event_date);

    const expiration = findContractExpirationDate(
      dayjs()
        .tz()
        .month(month)
        .year(year)
        .date(1),
      symbol,
      holidays
    );

    // if the user is  looking at an active contract, then we should use today's date to retrieve an ipf file,
    // as it is the latest one for this month
    const ipfFileDate = expiration.isSameOrAfter(dayjs().tz())
      ? dayjs()
          .tz()
          .format('YYYYMMDD')
      : expiration.format('YYYYMMDD');

    // if the contractStartDate is in the future, then the selected month is the current backMonth,
    // so we need to set the dateToFetch as the start date of the current frontMonth
    const contractStartDate = findContractStartDate(symbol, month, year);
    const dateToFetch = (contractStartDate.isAfter(dayjs().tz())
      ? findContractStartDate(symbol, month - 1, year)
      : contractStartDate
    ).format('YYYYMMDD');

    fetchData(dateToFetch, ipfFileDate, symbol);

    return () => {
      isMounted = false;
    };
  }, [selectedTimeframe]);

  // OptionChain expects data as an object with symbols as keys, so
  // we need to convert our array of option symbols into an object here
  const reducer = (object, datum) => {
    return {
      ...object,
      [datum.eventSymbol]: { ...datum }
    };
  };
  const dataForSelectedDate = optionsData
    .filter((datum) => {
      return (
        dayjs(datum.time)
          .utc()
          .format('MM/DD/YYYY') === selectedDate
      );
    })
    .reduce(reducer, {});

  const formattedSymbol = `/${symbol}${
    FUTURES_MONTH_CODES[selectedTimeframe.month.label]
  }${selectedTimeframe.year.value.toString().substr(2)}`;

  return (
    <BlockStyles template={template}>
      <Container className='block'>
        <OptionChain
          title={primary.options_title}
          description={primary.description}
          tooltipTitle={primary.tooltip_title && primary.tooltip_title.text}
          tooltip={primary.tooltip}
          template={template}
          fields={fields}
          date={selectedDate}
          data={dataForSelectedDate}
          optionSymbols={Object.keys(dataForSelectedDate)}
          month={selectedTimeframe.month.label}
          symbol={formattedSymbol}
          loading={loading}
          mode={'historical'}
        />
      </Container>
    </BlockStyles>
  );
}

HistoricalOptionChain.propTypes = propTypes;
HistoricalOptionChain.defaultProps = defaultProps;

export default HistoricalOptionChain;
